@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.workspace {
  padding: 10px 20px;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  width: 243px;
  margin-bottom: 12px;
  cursor: pointer;
  justify-content: space-between;

  .selectOrganizationName {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .selectorganizationRole {
    color: $grey500;
  }
}

.workspace.selected {
  border: 1px solid $primary;
}

.selectWorkspace {
  padding: 17px 24px;
  margin-top: 12px;
  border: 1px solid #eaedf5;
  box-sizing: border-box;

  /* Drop Box */

  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  .user {
    display: flex;
    flex-direction: row;
    margin-left: -5px;
    color: $grey500;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eaedf5;
    align-items: center;
  }

  .title {
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 14px;
  }
}
.selectWorkspace.noShadow {
  box-shadow: none;
}

.selectWorkspace.oppenned {
  position: absolute;
  z-index: 10000000;
  background-color: white;
}
