@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.login {
  display: flex;
  flex-direction: row;
  flex: 1;

  .leftImage {
    width: 50%;
    min-height: 100vh;
    content: "&nbsp";
    background: url("../assets/truck.png");
    background-size: cover;
  }

  .right {
    width: 50%;
    padding-top: 10vh;
    padding-left: 10vh;
    h5 {
      margin-bottom: 0px;
    }
    input {
      width: 100%;
    }

    .p2 {
      margin-top: 10px;
    }
  }

  // .validateBtn {
  //   float: right;
  // }

  .validateBtn {
    margin: auto;
    margin-top: 40px;
    float: none;
    color: white;
  }

  .successMessage {
    color: #00da87;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .content {
    margin: auto;
    width: 450px;
  }

  .button {
    margin-top: 40px;
  }

  .errorMessage {
    color: $primary;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .chooseWorkspaceExplanation {
    line-height: 24px;
    color: $grey500;
  }
}
