@import "./colors.scss";
@import "./sizes.scss";

.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  .title {
    font-size: $h2;
    color: $grey900;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .label {
    font-style: normal;
    font-weight: bold;
    font-size: $p3;
  }

  button {
    background: $primary;
    border-radius: 12px;
    width: 450px;
    border-color: $primary;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 50px;
    cursor: pointer;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  select,
  .input {
    width: 450px;
    border: 0px;
    border-bottom: 1px solid $grey400;
    border-radius: 0px;
    padding-left: 0px;
    color: $grey500;
    font-size: 16px;
  }

  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  select:focus {
    border-bottom: 1px solid $grey900;
    outline: none;
  }

  select,
  .input {
    width: 400px;
    padding: 12px;
    margin-top: 12px;
    width: 450px;
  }

  .closeBtn {
    position: absolute;
  }
}
