@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.storeContent {
  text-align: center;
  padding-top: 30px;
  background-color: #f3f4ff;
  min-height: 130vh;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  .logo {
    margin-bottom: 30px;
  }

  a {
    display: block;
    margin-top: 20px;
  }
}
