@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.custom-tooltip {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-family: "Inter-Bold";
}

.recharts-cartesian-axis-tick {
  // font-size: rem;
  font-family: "Inter";
}

.customized-legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Inter-Bold";
}

.legend-icon {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-top: 5px;
  margin-right: 5px;
}

.legend-item {
  display: flex;
  cursor: pointer;
}

.period {
  margin-top: 10px;
  cursor: pointer;
  color: $grey500;
}

.period:hover {
  font-weight: bold;
}
.selectedPeriod {
  margin-top: 10px;
  font-weight: bold;
  color: $primary;
}

.updateDate {
  text-align: right;

  /* Grey 500 */
  font-style: italic;
  color: $grey500;
}
