@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.customers {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.bold {
  color: $grey900;
  font-weight: bold;
}
