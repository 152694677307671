@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.deliveryNotes {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
.filterBar {
  // display: flex;
  // flex-direction: row;
  background-color: white;

  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}
.filterLine {
  color: $grey500;
  padding-bottom: 10px;
  border-bottom: 1px solid $grey500;
  margin-top: 20px;
}

.toggleFilterBtn {
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  color: $primary;
}

.toggleFilterBtn:hover {
  font-weight: bold;
}

.filters-openned {
  display: block;
}

.filters-closed,
.filter-simple-closed {
  display: none;
}

.filter-simple {
  width: 100%;
}

.input {
  margin-top: 10px;
}

.select {
  width: 280px;
  margin-right: 20px;
}

.filters {
  .row {
    align-items: center;
    input {
      margin-right: 20px;
    }

    label {
      width: 30%;
      span {
        margin-left: -15px;
        margin-right: 10px;
      }
    }
  }
}

.calendar-input {
  margin-left: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 110px;
  margin-top: 15px;
  cursor: pointer;

  .date-filter-badge {
    position: absolute;
    top: 10px;
    background: $green500;
    right: -3px;
    height: 10px;
    border-radius: 100px;
    padding: 2px 7px;
    color: white;
    border: 1px solid #ffffff;
  }
}

.date-filter-badge.vehicle {
  top: 38px;
  right: initial;

  margin-left: 20px;
}

.calendar-input.dateselected {
  background-color: $primary;
}

.clearDates {
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  text-decoration: underline;
  color: $green700;
}

.clearDates:hover {
  font-weight: bold;
}
.react-datepicker {
  right: 150px;
}
.react-datepicker-popper {
  z-index: 1000 !important;
}
.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-wrapper {
  margin-right: 18px;
}
.calendar {
  background-color: white;
  font-family: "Inter" !important;

  background: #ffffff;
  border: 1px solid #eaedf5 !important;
  box-sizing: border-box;
  /* Drop Box */

  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
}

.react-datepicker__day {
  border-radius: 0px !important;
  margin: 0px !important;
  padding: 0.166rem !important;
}
.react-datepicker__day--outside-month {
  color: #e8d8d8 !important;
}

.react-datepicker__day--in-range {
  background-color: #f7f7f7 !important;
  color: black !important;
}
.react-datepicker__day--in-selecting-range {
  background-color: #f7f7f7 !important;
  color: black !important;
}
.react-datepicker__day--in-selecting-range:hover {
  background-color: $green700 !important;
  color: white !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-start:focus,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--range-end {
  background-color: $green700 !important ;
  border: none;
  outline: none;
  color: white !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  border-radius: 10px !important;
}

.react-datepicker__day--range-start {
  // border-radius: 0px!important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.react-datepicker__day--range-end {
  // border-radius: 0px!important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: inherit !important;

  .react-datepicker__day--disabled {
    color: #ccc !important;
  }
}

.popup-filtercolumns {
  position: absolute;
  right: 10px;
  background-color: white;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  padding: 14px;
  // top: 98px;
  border: 1px solid #eaedf5;
  z-index: 13;
}
