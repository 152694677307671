@import "../styles/colors.scss";
@import "../styles/sizes.scss";
.menu {
  width: 400px;

  background: white;

  .selectWorkspace {
    width: fit-content;
    background-color: white;
    // width: 15vw;
    border: none;
    // overflow-y: auto;
    // max-height: 94vh;
  }

  .versionNumber {
    color: $grey500;
    text-align: center;
    margin-top: 20px;
  }

  .links {
    padding: 6px 15px;
    cursor: pointer;
  }
  .link {
    display: flex;
    color: $grey500;
    align-items: center;
  }
  .linkSelected {
    display: flex;
    color: $primary;
    font-weight: 700;
    align-items: center;
    .icon {
      color: $primary;
    }
  }

  .organizationName {
    margin: 17px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .block {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $grey400;
  }
  .userEmail {
    text-align: "center";
    color: $grey500;
    margin: 17px;
    margin-bottom: 10px;
  }

  .account {
    text-align: "center";
    color: $grey500;
    margin: 17px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .linkSelected.account {
    color: $primary;
  }

  .logoName {
    background-color: $primary;
    font-weight: bold;
    font-size: $h2;
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
    border-bottom: 1px solid $grey400;
  }

  @media (min-height: 950px) {
    .bottom {
      position: absolute;
      bottom: 10px;
      z-index: 2;
    }
  }
  .bottom {
    width: 291px;
  }
}
