@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.deliveryNote {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .securityMeasuresLabel {
    color: $grey500;
    margin-bottom: 5px;
    margin-left: 20px;
  }

  .right {
    background-color: $grey300;
    width: 85vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .productRow {
    display: flex;
    flex-direction: row;
  }

  .driverPhotos {
    display: flex;
    flex: row;
    margin-top: 20px;
    img {
      height: 100px;
      margin-right: 20px;
      border-radius: 12px;
      cursor: pointer;
    }
  }

  .cancelBtn {
    background-color: $orange;
    border: 1px solid orange;
  }

  .updateDate {
    text-align: right;

    /* Grey 500 */
    font-style: italic;
    color: $grey500;
  }

  .notificationMailContainer {
    position: relative;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    margin-bottom: 5px;
  }

  .emailList {
    display: none;
    background-color: white;
    position: absolute;
    top: 100px;
    padding: 12px;
    width: 235px;
    border-radius: 12px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);

    .title {
      margin-top: 20px;
      margin-bottom: 10px;
      color: $grey900;
    }
    .email {
      color: $grey500;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }

    label {
      color: $grey500;
      margin-bottom: 5px;
    }

    .errorMessage {
      margin-top: 10px;
    }
    input {
      margin-top: 0px;
    }
    .eye {
      margin-top: -3px;
    }
  }

  .emailList.visible {
    display: block;
  }

  .hour {
    background-color: white;
    padding: 20px;

    .label {
      margin-bottom: 3px;
      font-size: $p3;
      font-weight: bold;
    }

    .time {
      color: $grey500;
    }
  }

  .documents {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .clientComment {
    padding-top: 20px;
    color: $grey500;
  }

  .details {
    display: flex;
    flex-direction: row;
    margin-bottom: 200px;
    min-height: 160px;
    > div {
      width: 50%;
    }
    > div:first-child {
      border-right: 1px solid $grey400;
    }
  }

  .signerName {
    font-weight: bold;
    margin-top: 12px;
  }

  .tracking {
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: $primary;
    background-color: #e9effd;
  }
}
