@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.errorMessage {
  padding-left: 4px;
  color: $error;
  font-weight: bold;
  padding-top: 4px;
  width: 250px;
}
