@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.updatePassword {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  button {
    background: $primary;
    border-radius: 4px;
    width: 450px;
    border-color: $primary;
    color: white;
    padding: 15px 41px;
    margin-top: 50px;
    cursor: pointer;
    font-weight: bold;
  }

  input[type="password"],
  input[type="text"] {
    width: 450px;
    border: 0px;

    border-bottom: 1px solid $grey400;
    border-radius: 110px;
    padding-left: 10px;
    color: $grey500;
    font-size: 16px;
  }

  input[type="password"]:focus,
  input[type="text"]:focus {
    border-bottom: 1px solid $grey900;
    outline: none;
  }
}
