@import "../styles/colors.scss";

#privacy {
  #privacyContent {
    margin: auto;
    max-width: 80%;
  }
  h1,
  h2,
  h3,
  b {
    color: $primary;
  }
}
