@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.users {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.userExtraInfoTab {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 10px;

  div {
    width: 40%;
    text-align: center;
    padding: 10px;
    border: 1px solid $primary;
    background-color: $primary;
    color: white;
    border-radius: 10px;
  }
  div.selected {
    background-color: grey;
    border: 1px solid grey;
  }
}

.sitechoice {
  .css-26l3qy-menu {
    overflow-y: scroll !important;
    // height: 80px;
    z-index: 300000;
    display: block;
    position: relative;
    // ;
  }
}

.role {
  font-style: italic;
}
