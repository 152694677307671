.tabButton {
  // margin-right: 20px;
  cursor: pointer;
  color: $primary;

  padding: 10px 20px;
  font-size: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  // border-bottom: 1px solid $primary;
}

.tabButton.selected {
  // text-decoration: underline;
  font-weight: bold;
  background-color: white;
}
