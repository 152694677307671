@import "./styles/colors.scss";
@import "./styles/sizes.scss";

.returnBtn {
  display: flex;
  margin-top: 30px;
  cursor: pointer;
}

.primary {
  color: $primary !important;
}
