@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.confirmPopup {
  button {
    width: 40% !important;
  }

  .row {
    justify-content: space-between;
  }

  .cancel {
    background-color: $grey900 !important;
    border-color: $grey900;
    // color : $gray;
  }
  .close {
    margin-top: 20px;
    align-self: center;
    cursor: pointer;
    text-decoration: underline;
    :hover {
      font-weight: bold;
    }
  }
}

.confirmPopup.popup {
  width: 469px;
}
