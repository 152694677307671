@import "../styles/colors.scss";
@import "../styles/sizes.scss";

// .customerEmail {
//   margin-top: 10px;
//   display: flex;
//   flex-direction: row;
//   color: $grey900;
//   .icon {
//   }
//   span {
//     // height: 25px;
//   }
// }

// .popup {
//   width: 450px;
// }

.signCanvas {
  border: 1px solid black;
  border-radius: 10px;
  border: 1px solid #eaedf5 !important;
  box-sizing: border-box;
  margin-top: 10px;
  /* Drop Box */

  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
}

.resetButton {
  position: relative;
  top: 49px;
  left: 367px;

  :hover {
    cursor: pointer;
  }
}
