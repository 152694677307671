$primary: #2052f5;
$primary50: #2052f54d;

$error: #c0392b;

$green: #80cbc4;
$white: #ffffff;

$white50: rgba(255, 255, 255, 0.5);
$grey900: #010f4b;
$orange: #f26f37;

$grey500: #90a0b7;
$grey50010: #90a0b71a;
$grey400: #e3e7f2;
$grey300: #f5f6f9;
$grey100: #fdfdff;

$grey0: #262628;
$grey040: rgba(38, 38, 40, 0.4);
$iconBlack: #231f20;

$black900: #1a202c;
$black500: rgba(0, 0, 0, 0.5);
$black300: rgba(0, 0, 0, 0.3);
$black100: rgba(0, 0, 0, 0.1);
$black50: rgba(0, 0, 0, 0.05);
$black40: rgba(0, 0, 0, 0.04);

$green500: #2bc99b;
$green50010: #80cbc41a;

$green700: #2bc99b;
