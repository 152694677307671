@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.customerEmail {
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  color: $grey900;
  .icon {
  }
  span {
    // height: 25px;
  }
}

.popup {
  width: 450px;
}
