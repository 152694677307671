@font-face {
  font-family: "Inter";
  src: local("Inter-Regular"), url(./assets/fonts/Inter-Regular-slnt=0.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"), url(./assets/fonts/Inter-Bold-slnt=0.ttf) format("truetype");
}

@import "./styles/colors.scss";
@import "./styles/tab.scss";

body {
  margin: 0;
  color: $black900;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  overflow-y: auto !important;
  background-color: $grey300;
}

.languageChoose {
  position: absolute;
  right: 3vw;
  top: 10px;
}

a {
  text-decoration: none;
}

.row {
  display: flex;
  flex-direction: row;
}

.align-center {
  align-items: center;
}
.right {
  padding-top: 4vh;
  padding-left: 3vw;
  padding-right: 3vw;
}
.space-between {
  justify-content: space-between;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
}

h1 {
  font-weight: 700;
  font-family: "Inter";
  font-size: 30px;
}
h2 {
  font-weight: "bold";
  font-family: "Inter-Bold";
  font-size: 26px;
}
h3 {
  font-weight: "bold";
  font-family: "Inter-Bold";
  font-size: 22px;
}
h4 {
  font-weight: "bold";
  font-family: "Inter-Bold";
  font-size: 16px;
}

h5 {
  font-weight: 500;
  font-family: "Inter";
  font-size: 14px;
}

h6 {
  font-weight: 500;
  font-family: "Inter";
  font-size: 13px;
}

.p1 {
  font-size: 16px;
}
.p2 {
  font-size: 13px;
}

.p3 {
  font-size: 14px;
}

.row {
  display: "flex";
  flex-direction: "row";
}

.tooltip {
  background-color: $grey900;
}

.link {
  color: $primary;
  font-weight: bold;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.sectionTitle {
  margin-top: 40px;
  margin-bottom: 20px;
}

.co2Panel {
  background-color: white;
  padding: 20px;
  margin-bottom: 15px;
  color: #4f7c14;
  font-style: italic;
  font-weight: 600;
}
.panelContent {
  border: 1px solid #dcdcdc;
  padding: 18px;
  border-bottom-width: 0px;
}

.panelTitle {
  display: "flex";
  flex-direction: "row";
  justify-content: "space-between";

  background-color: $grey100;
  border: 1px solid #dcdcdc;
  padding: 18px;
  // border-bottom-width: 0px;
}

.noborder-top {
  border-top: 0px;
}

.quantityLine {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}

.quantityLine > div {
  width: 50%;
}

.panelRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.panelRow > div {
  //   width: 50%;
  flex: 50%;
}

.panelRow.oneChild > div {
  flex: 100%;
}

.panelRow > div:nth-child(2n) > .panelTitle {
  border-left-width: 0px;
  //   width: 50%;
}

.panelTitleText {
  color: $grey0;
  font-weight: 500;
  margin-bottom: 4px;
}

.panelSubTitle {
  color: $grey500;
}

.closeBtn {
  justify-content: "center";
  margin-right: 30px;
  background-color: $grey100;
  border-radius: 12px;
  padding: 19px;
}

.validateBtn {
  // flex: 1;
  cursor: pointer;
  justify-content: "center";
  align-items: "center";
  border: 1px solid $primary;
  border-radius: 10px;
  background-color: $primary;

  padding: 12px;
  width: fit-content;
  height: fit-content;
  margin-top: 30px;

  padding: 15px 18px;
  a {
    text-decoration: none;
  }
  h6 {
    color: white;
    margin: 0px;
  }
}

.cancelBtn {
  cursor: pointer;
  justify-content: "center";
  align-items: "center";
  border: 1px solid $grey500;
  border-radius: 4px;
  background-color: $grey500;

  padding: 12px;
  width: fit-content;
  height: fit-content;
  margin-top: 30px;

  padding: 15px 41px;
  a {
    text-decoration: none;
  }
  h6 {
    color: white;
    margin: 0px;
  }
}

.validateBtn.alternate {
  // flex: 1;
  cursor: pointer;
  justify-content: "center";
  align-items: "center";
  border: 1px solid $primary;
  border-radius: 4px;
  background-color: white;

  padding: 12px;
  width: fit-content;
  height: fit-content;
  margin-top: 30px;

  padding: 15px 18px;
  h6 {
    color: $primary;
    margin: 0px;
  }
}

.btn {
  // flex: 1;
  cursor: pointer;
  justify-content: "center";
  align-items: "center";
  border: 1px solid $primary;
  border-radius: 10px;
  background-color: $primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter";
  width: fit-content;
  height: fit-content;

  padding: 10px 15px;
  a {
    text-decoration: none;
  }
  h6 {
    margin: 0px;

    margin-left: 8px;
    color: white;
    font-family: "Inter";
  }
}
.card {
  border-radius: 12px;
  padding: 16px;
  display: "flex";
  flex-direction: "row";
}
.cardIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 18px;
  align-content: "center";
  justify-content: "center";
  margin-right: 16px;
}

input {
  margin-top: 10px;
  padding: 13px 20px;
  padding-left: 20px;
  border-radius: 10px;
  border: 1px solid $grey400;
  // border-color: $grey400;
  box-sizing: border-box;
}

input::placeholder {
  color: $grey500;
}
.grey500 {
  color: $grey500;
}
/* cardText {}
  blackButtonContainer: {
    paddingTop: 15;
    paddingBottom: 15;
    paddingLeft: 30;
    paddingRight: 30;
    zIndex: 3;
    width: '100%';
    background-color: '#FFF';
    shadowOffset: { width: 30; height: 200 }
    shadowColor: 'rgba(163; 163; 164; 0.5)';
    shadowOpacity: 1;
    elevation: 0;
  } */

.blackButton {
  background-color: #2d2d2d;
  border-radius: 12px;
  padding: 57px 16px;
}

.firstColumn {
  color: $black900;
}

.checkbox {
  cursor: pointer;
}

.hide {
  display: none;
}

.red-badge {
  background-color: #e74c3c;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: 0px;
  margin-left: 1px;
}
