@import "../styles/colors.scss";
@import "../styles/sizes.scss";

.document {
  display: flex;
  flex-direction: row;
  margin-right: 10px;

  .icon {
    background-color: $grey100;
    padding: 13px 11px;
    border-radius: 10px;
    margin-right: 16px;
  }
  .name {
    margin-top: auto;
    margin-bottom: auto;
    color: $grey500;
  }
}
